const BOOKS_FOR_PAGE = 20;
const SERIES_FOR_PAGE = 40;
const TAGS_FOR_PAGE = 40;
const GENRES_FOR_PAGE = 40;
const AUTHORS_FOR_PAGE = 40;
const PRERENDER_PAGES = 1;

const RUSSIAN_ALPHABET = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
const ENGLISH_ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const API_URL = `${process.env.APP_URL}`;

const REVALIDATE_TIME = 300;

const CATEGORY_LINK = {
  books: 'Книги',
  authors: 'Авторы',
  genres: 'Жанры',
  series: 'Серии',
  tags: 'Тэги',
  drafts: 'Черновики',
  audio_books: 'Аудиокниги',
  podcasts: 'Подкасты',
};

const ABSOLUTE_URL = 'https://bookz.ru';
const DEV_ABSOLUTE_URL = '';
//Отображение картинок на dev
// const DEV_ABSOLUTE_URL = ABSOLUTE_URL;

const WIDGETS_API = {
  main: `${API_URL}main-page/`,
};

const otherAuthorsBooksSliderConfig = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3500,
  initialSlide: 0,
  variableWidth: true,
  slidesToScroll: 1,
  slidesToShow: 4,
  swipe: false,
  responsive: [
    {
      breakpoint: 1060,
      settings: {
        swipe: true,
        arrows: false,
        touchThreshold: 1000000,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 530,
      settings: {
        swipe: true,
        arrows: false,
        slidesToShow: 1,
        slidesToSwipe: 1,
        touchThreshold: 1000000,
        swipeToSlide: true,
      },
    },
  ],
};

export {
  BOOKS_FOR_PAGE,
  SERIES_FOR_PAGE,
  GENRES_FOR_PAGE,
  AUTHORS_FOR_PAGE,
  RUSSIAN_ALPHABET,
  ENGLISH_ALPHABET,
  API_URL,
  PRERENDER_PAGES,
  config,
  REVALIDATE_TIME,
  CATEGORY_LINK,
  DEV_ABSOLUTE_URL,
  ABSOLUTE_URL,
  TAGS_FOR_PAGE,
  WIDGETS_API,
  otherAuthorsBooksSliderConfig,
};
